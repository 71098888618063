import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { ListItemText, Tooltip } from '@mui/material';
// locales
import { useLocales } from '../../../locales';
//
import Iconify from '../../iconify';
import { StyledItem } from './styles';
// ----------------------------------------------------------------------
const NavItem = forwardRef(({ item, depth, open, active, isExternalLink, ...other }, ref) => {
    const { translate } = useLocales();
    const { title, path, icon, children, disabled, caption, roles } = item;
    const subItem = depth !== 1;
    const renderContent = (_jsxs(StyledItem, { ref: ref, open: open, depth: depth, active: active, disabled: disabled, ...other, children: [_jsx(ListItemText, { primary: `${translate(title)}`, primaryTypographyProps: {
                    noWrap: true,
                    sx: {
                        width: 72,
                        fontSize: 10,
                        lineHeight: '16px',
                        textAlign: 'center',
                        ...(active && {
                            fontWeight: 'fontWeightMedium',
                        }),
                        ...(subItem && {
                            fontSize: 14,
                            width: 'auto',
                            textAlign: 'left',
                        }),
                    },
                } }), caption && (_jsx(Tooltip, { title: `${translate(caption)}`, arrow: true, placement: "right", children: _jsx(Iconify, { icon: "eva:info-outline", width: 16, sx: {
                        top: 11,
                        left: 6,
                        position: 'absolute',
                    } }) })), !!children && (_jsx(Iconify, { width: 16, icon: "eva:chevron-right-fill", sx: {
                    top: 11,
                    right: 6,
                    position: 'absolute',
                } }))] }));
    const renderItem = () => {
        // ExternalLink
        if (isExternalLink)
            return (_jsx(RouterLink, { to: path, style: { textDecoration: 'none' }, children: renderContent }));
        // Default
        return (_jsx(RouterLink, { to: path, style: { textDecoration: 'none' }, children: renderContent }));
    };
    return _jsxs(_Fragment, { children: [" ", renderItem(), " "] });
});
export default NavItem;
